/******* screen-small.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
@font-face {
  font-family: "DINNextLight";
  src: url("/extras/fonts/fb0dd5e6-cf57-43be-8f3f-2880d5389694.eot?#iefix");
  src: url("/extras/fonts/fb0dd5e6-cf57-43be-8f3f-2880d5389694.eot?#iefix") format("eot"), url("/extras/fonts/14aba99e-8c97-4daa-a8df-67920a051402.woff2") format("woff2"), url("/extras/fonts/96fd6d6f-fad6-4dd9-ab70-29547a56cacd.woff") format("woff"), url("/extras/fonts/cdc1c0e4-213b-4a01-a1dc-af6dddf2263a.ttf") format("truetype");
}
@font-face {
  font-family: "DINNextRegular";
  src: url("/extras/fonts/936930ba-a8da-4765-9e89-0f35cdff223f.eot?#iefix");
  src: url("/extras/fonts/936930ba-a8da-4765-9e89-0f35cdff223f.eot?#iefix") format("eot"), url("/extras/fonts/6b4d4830-9c7d-43d2-b5d3-c73f739561b9.woff2") format("woff2"), url("/extras/fonts/ccf25ada-6d2a-4133-95dc-deb039e22999.woff") format("woff"), url("/extras/fonts/126195b4-2fa0-4b95-b5cf-ad9bf10193f0.ttf") format("truetype");
}
@font-face {
  font-family: "DINNextMedium";
  src: url("/extras/fonts/c6dbdd54-50f5-472a-8b74-0e98ef77e33e.eot?#iefix");
  src: url("/extras/fonts/c6dbdd54-50f5-472a-8b74-0e98ef77e33e.eot?#iefix") format("eot"), url("/extras/fonts/afabdfcf-432c-49b4-a496-0e5051e87fa9.woff2") format("woff2"), url("/extras/fonts/611ea865-db7e-4c05-94b8-bd1675c90576.woff") format("woff"), url("/extras/fonts/e65314e4-c389-45b8-99a9-26ab6aef066c.ttf") format("truetype");
}
@font-face {
  font-family: "DINNextBold";
  src: url("/extras/fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix");
  src: url("/extras/fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix") format("eot"), url("/extras/fonts/03008527-67bd-478e-98e3-3b56dd9a6520.woff2") format("woff2"), url("/extras/fonts/557f817d-9c25-4a23-baaf-a3cf84a7cd7c.woff") format("woff"), url("/extras/fonts/92ed1479-1d40-4a94-8baf-6abd88b17afa.ttf") format("truetype");
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
address {
  font-style: normal;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.fakeUnit,
.part,
.fakePart {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.fakeArea,
.unit,
.fakeUnit,
.unit > .body,
.unit > form > .body,
.part,
.fakePart {
  float: left;
}
.unit,
.fakeUnit {
  margin-top: 0;
  margin-bottom: 0;
}
.unit > .body,
.fakeUnit > .body,
.unit > form > .body {
  width: 100%;
}
.fold > .body {
  min-height: 0;
}
.part,
.fakePart,
.grid table {
  margin-top: 30px;
  margin-bottom: 30px;
}
.lead {
  margin-bottom: 0 !important;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
video {
  float: left;
  width: 100%;
}
.line > hr {
  float: left;
  width: 100%;
  height: 0;
  border-color: #B4B4B4;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #000;
  font-family: 'DINNextLight', helvetica, sans-serif;
  line-height: 1.33333333;
  font-size: 18px;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset,
#disp .part.recaptcha-info {
  padding: 0.2em 0 0.5em;
}
#disp .part.recaptcha-info {
  width: 100%;
  margin: 0;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding: 0 15px 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  float: right;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
.srch .text {
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-bottom: 0.8em;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #1A4066;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #1A4066;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
.grow {
  text-transform: none !important;
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 0;
}
.table--headline th {
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
}
.table td {
  padding: 5px 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
}
@media (max-width: 767px) {
  .table {
    font-size: 2.34680574vw;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(26, 64, 102, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  width: 100%;
  background-color: transparent;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 1200px;
  background: #fff;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#home {
  float: left;
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
  margin-top: 60px;
}
.cb-layout3 #head,
.cb-layout4 #head {
  margin-top: 120px;
}
@media (max-width: 767px) {
  .cb-layout3 #head,
  .cb-layout4 #head {
    margin-top: 60px;
  }
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  color: #000;
  font-family: 'DINNextLight', helvetica, sans-serif;
  line-height: 1.33333333;
  font-size: 18px;
  letter-spacing: 0.025em;
}
.section {
  float: left;
  width: 100%;
  position: relative;
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .section {
  transform: translateY(200px);
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 2px 2px rgba(180, 180, 180, 0.4);
  z-index: 2001;
}
body.cb-toggle-target-active .section--header {
  transform: translateY(0);
}
.headercontent {
  float: left;
  width: 100%;
}
@media (min-width: 1024px) {
  .headercontent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.navigation {
  float: left;
  margin-top: 72px;
}
.content {
  float: left;
  width: 100%;
  overflow: hidden;
}
.content--video {
  display: none;
}
.cb-layout1 .content--video {
  display: block;
}
.content--lightgray {
  background-color: #EDEDED;
}
.content--blue,
.content--lightblue {
  background: #1A4066;
  color: #fff;
}
.content--blue .line > hr,
.content--lightblue .line > hr {
  border-color: #fff;
}
.content--blue h2,
.content--lightblue h2,
.content--blue h3,
.content--lightblue h3,
.content--blue h4,
.content--lightblue h4,
.content--blue h5,
.content--lightblue h5 {
  color: #fff;
}
.content--blue .pale,
.content--lightblue .pale {
  color: #fff;
  border-color: #fff;
}
.content--blue .skew,
.content--lightblue .skew {
  background-image: url(/images/quotes.svg);
}
.content--blue div.link a.open,
.content--lightblue div.link a.open,
.content--blue div.load a.open,
.content--lightblue div.load a.open,
.content--blue div.link span.open,
.content--lightblue div.link span.open,
.content--blue div.load span.open,
.content--lightblue div.load span.open,
.content--blue div.link a.load,
.content--lightblue div.link a.load,
.content--blue div.load a.load,
.content--lightblue div.load a.load {
  border-color: #fff;
  color: #fff;
}
.content--blue div.link a.open:after,
.content--lightblue div.link a.open:after,
.content--blue div.load a.open:after,
.content--lightblue div.load a.open:after,
.content--blue div.link span.open:after,
.content--lightblue div.link span.open:after,
.content--blue div.load span.open:after,
.content--lightblue div.load span.open:after,
.content--blue div.link a.load:after,
.content--lightblue div.link a.load:after,
.content--blue div.load a.load:after,
.content--lightblue div.load a.load:after {
  background-color: #fff;
  background-image: url(/images/link-black.svg);
}
.content--blue div.link a.open:hover,
.content--lightblue div.link a.open:hover,
.content--blue div.load a.open:hover,
.content--lightblue div.load a.open:hover,
.content--blue div.link span.open:hover,
.content--lightblue div.link span.open:hover,
.content--blue div.load span.open:hover,
.content--lightblue div.load span.open:hover,
.content--blue div.link a.load:hover,
.content--lightblue div.link a.load:hover,
.content--blue div.load a.load:hover,
.content--lightblue div.load a.load:hover,
.content--blue div.link a.open:focus,
.content--lightblue div.link a.open:focus,
.content--blue div.load a.open:focus,
.content--lightblue div.load a.open:focus,
.content--blue div.link span.open:focus,
.content--lightblue div.link span.open:focus,
.content--blue div.load span.open:focus,
.content--lightblue div.load span.open:focus,
.content--blue div.link a.load:focus,
.content--lightblue div.link a.load:focus,
.content--blue div.load a.load:focus,
.content--lightblue div.load a.load:focus {
  background: #fff;
  border-color: #fff;
  color: #000;
}
.content--blue .ns-teaser,
.content--lightblue .ns-teaser {
  color: #fff;
}
.content--lightblue {
  background-color: #0694D3;
}
#view.dynamicSide--empty .content--side,
#view.dynamicBase--empty .content--base,
#view.dynamicFareast--empty .content--fareast,
#view.dynamicNorth--empty .content--north,
#view.dynamicEast--empty .content--east,
#view.dynamicFarwest--empty:not(.cb-layout2) .content--farwest {
  display: none;
}
#view.dynamicMain--empty .content--main .area,
#view.cb-layout2.dynamicFarwest--empty .content--farwest .area {
  display: none;
}
.cb-layout4 .content--norm {
  display: none;
}
.cb-layout4 .content--norm.content--intro,
.cb-layout4 .content--norm.content--main {
  display: block;
  position: relative;
}
.content-overlay {
  float: left;
  width: 102%;
}
.content-overlay.content-overlay--top,
.content-overlay.content-overlay--foot {
  margin-left: -2%;
  margin-top: -1px;
}
.content-overlay.content-overlay--bottom {
  margin-right: -2%;
  margin-bottom: -1px;
}
.content--intro .content-overlay {
  display: none;
}
.cb-layout3 .content--intro .content-overlay {
  display: block;
}
.footcontent {
  float: left;
  width: 100%;
  background-color: #0694D3;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.footcontent a {
  color: #fff;
  text-decoration: none;
}
.footdesk {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 9.66666667%;
  padding-right: 9.66666667%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 1023px) {
  .footdesk {
    padding-left: 3.77604167%;
    padding-right: 3.77604167%;
  }
}
@media (max-width: 767px) {
  .footdesk {
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: column;
  }
}
.footunit {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .footunit {
    margin-bottom: 40px;
    width: 100%;
  }
}
.footunit--1 {
  margin-right: 60px;
}
@media (max-width: 767px) {
  .footunit--1 {
    margin-bottom: 0;
    margin-right: 0;
  }
}
.foothome {
  float: left;
  height: 140px;
}
@media (max-width: 767px) {
  .foothome {
    height: 80px;
  }
}
.vcard {
  float: left;
  margin-top: 100px;
}
.vcard .org {
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .vcard {
    margin-top: 40px;
  }
}
.socialWrapper {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 80px;
}
@media (max-width: 767px) {
  .socialWrapper {
    margin-top: 40px;
  }
}
.socialTitle {
  float: left;
  margin-bottom: 10px;
}
#social {
  float: left;
}
#social .meta {
  float: left;
  width: 36px;
  height: 36px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-linkedin-white.svg);
  margin-right: 12px;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta:hover,
#social .meta:focus {
  opacity: 0.8;
}
#social .meta.service_xing {
  background-image: url(/images/social-xing-white.svg);
}
#social .meta.service_instagram {
  background-image: url(/images/social-instagram-white.svg);
}
#social .meta.service_facebook {
  background-image: url(/images/social-facebook-white.svg);
}
#social .meta.service_youtube {
  background-image: url(/images/social-youtube-white.svg);
}
.newsletter {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 100px;
}
@media (max-width: 767px) {
  .newsletter {
    margin-top: 40px;
    width: 100%;
  }
}
.newsletter__content {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.newsletter__text {
  float: left;
  margin-bottom: 10px;
}
.newsletter__button {
  float: left;
  display: block;
  position: relative;
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: 0.107em;
  border: 1px solid #fff;
  text-decoration: none;
  padding: 14px 30px;
  background-color: transparent;
  border-radius: 0;
  color: #fff;
  height: 48px;
  box-sizing: border-box;
}
.newsletter__button:hover,
.newsletter__button:focus {
  background-color: #fff;
  color: #000;
}
#root #mc_embed_signup form {
  padding: 0;
  float: left;
}
#root #mc_embed_signup label {
  text-align: left;
  font-size: 18px;
  line-height: 1.33333333;
}
#root #mc_embed_signup input.email {
  font-family: 'DINNextLight', helvetica, sans-serif;
  line-height: 1.33333333;
  width: 260px;
  padding: 10px;
  border-radius: 0;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  height: 48px;
  box-sizing: border-box;
}
#root #mc_embed_signup input.email::placeholder {
  color: #fff;
}
@media (max-width: 1023px) {
  #root #mc_embed_signup input.email {
    width: 100%;
  }
}
@media (max-width: 1023px) {
  #root #mc_embed_signup .clear {
    width: 100%;
  }
}
#root #mc_embed_signup .button {
  float: left;
  display: block;
  position: relative;
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: 0.107em;
  border: 1px solid #fff;
  text-decoration: none;
  padding: 14px 30px;
  background-color: transparent;
  border-radius: 0;
  color: #fff;
  height: 48px;
}
#root #mc_embed_signup .button:hover,
#root #mc_embed_signup .button:focus {
  background-color: #fff;
  color: #000;
}
@media (max-width: 1023px) {
  #root #mc_embed_signup .button {
    width: 100%;
    margin-top: 4px;
  }
}
#services {
  float: left;
  align-self: flex-end;
  margin-top: 80px;
  font-size: 12px;
  line-height: 1.66666667;
}
@media (max-width: 767px) {
  #services {
    margin-top: 40px;
  }
}
.moodcontent {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.cb-layout3 .moodcontent {
  display: none;
}
.mood-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 102%;
  display: none;
}
.cb-layout1 .mood-overlay,
.cb-layout2 .mood-overlay {
  display: block;
}
.spickel {
  float: left;
  width: 102%;
  margin-left: -2%;
  display: none;
}
.cb-layout3 .spickel {
  display: block;
}
.backlink {
  position: absolute;
  right: 9.58333333%;
  top: 66px;
  width: 60px;
  height: 60px;
  background-color: #fff;
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/close.svg);
  cursor: pointer;
  border-radius: 100px;
  box-shadow: 0 0 4px rgba(120, 120, 120, 0.6);
}
@media (max-width: 1023px) {
  .backlink {
    right: 3.7414966%;
  }
}
@media (max-width: 767px) {
  .backlink {
    right: 5%;
    top: 30px;
    width: 40px;
    height: 40px;
    background-size: 20px 20px;
  }
}
.video {
  float: left;
  width: 100%;
}
@media (max-width: 972px) {
  .video {
    height: 830px;
    object-fit: cover;
    object-position: center;
  }
}
@media (max-width: 738px) {
  .video {
    height: 630px;
  }
}
.videocontent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
.playbutton {
  float: left;
  align-self: center;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  padding-top: 120px;
  background-size: 100px 100px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-image: url(/images/playbutton.svg);
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.191em;
  text-align: center;
  min-width: 100px;
  transition: all 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.playbutton:hover {
  color: #fff;
  background-size: 90px 90px;
  background-position: 50% 5px;
}
@media (max-width: 767px) {
  .playbutton {
    background-size: 60px 60px;
    padding-top: 74px;
    font-size: 11px;
  }
  .playbutton:hover {
    background-size: 60px 60px;
    background-position: 50% 0;
  }
}
.downlink {
  float: left;
  margin-bottom: 224px;
  align-self: center;
  width: 30px;
  height: 30px;
  background-size: 30px 15px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-image: url(/images/downlink.svg);
  animation-name: bounce;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.05, 0.8, 0.5, 1);
  cursor: pointer;
}
@media (max-width: 1200px) {
  .downlink {
    margin-bottom: 18.66666667vw;
  }
}
@media (max-width: 767px) {
  .downlink {
    width: 20px;
    height: 20px;
    background-size: 20px 10px;
  }
}
@keyframes bounce {
  0% {
    background-position: 50% 100%;
  }
  70% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 50% 100%;
  }
}
.cb-share .cb-service {
  float: left;
  margin-right: 10px;
}
.cb-share .cb-service:last-child {
  margin-right: 0;
}
.cb-share .cb-service * {
  display: block;
  float: left;
}
#cb-cookie-warning {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1990;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none !important;
}
.cb-cookie-warning--container {
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  background-color: #1A4066;
  color: #FFF;
  font-family: 'DINNextLight', helvetica, sans-serif;
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.375;
  transform: translateY(200%);
  transition: all 0.5s 0.5s cubic-bezier(0.05, 0.8, 0.5, 1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 13px 5vw;
}
@media (max-width: 1023px) {
  .cb-cookie-warning--container {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.show-content .cb-cookie-warning--container {
  transform: translateY(0);
}
.cb-cookie-warning--text {
  float: left;
  padding-right: 20px;
}
.cb-cookie-warning--text .cookie--link {
  display: inline-block;
}
@media (max-width: 767px) {
  .cb-cookie-warning--text {
    text-align: left;
    padding-right: 0px;
  }
}
.cb-cookie-warning--actions {
  float: left;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  flex-direction: row-reverse;
  min-width: 260px;
}
.cb-cookie-warning__button {
  float: left;
  display: block;
  background-color: #1A4066;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
  border: 1px solid #fff;
  color: #FFF;
  margin: 7px 5px 7px;
}
.cb-cookie-warning__button:hover,
.cb-cookie-warning__button:focus {
  color: #fff;
  background-color: #1d4872;
  text-decoration: none;
  cursor: pointer;
}
.cb-cookie-warning__button:active {
  outline: none;
  background-color: #10263d;
}
@media (max-width: 1023px) {
  .cb-cookie-warning__button {
    margin-top: 20px;
  }
}
#cb-cookie-warning__button--accept {
  background-color: #FFF;
  color: #1A4066;
}
#cb-cookie-warning__button--accept:hover,
#cb-cookie-warning__button--accept:focus {
  background-color: rgba(255, 255, 255, 0.8);
}
.titles {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
  padding-left: 9.66666667%;
  padding-right: 9.66666667%;
  margin-bottom: 120px;
}
.cb-layout3 .titles {
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .titles {
    padding-left: 3.77604167%;
    padding-right: 3.77604167%;
  }
}
@media (max-width: 767px) {
  .titles {
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 60px;
  }
  .cb-layout3 .titles {
    margin-bottom: 60px;
  }
}
.titles__section {
  float: left;
}
.titles__section--1 {
  width: 82.6446281%;
}
.cb-layout3 .titles__section--1 {
  align-self: flex-start;
  width: 64.46280992%;
}
@media (max-width: 767px) {
  .titles__section--1 {
    width: 100%;
  }
  .cb-layout3 .titles__section--1 {
    width: 100%;
  }
}
.titles__section--2 {
  align-self: flex-start;
  width: 64.46280992%;
}
@media (max-width: 767px) {
  .titles__section--2 {
    width: 100%;
  }
}
.cbdModule--titleLead {
  margin-top: 60px;
}
.cbdModule--titleLead .titleLead {
  font-size: 30px;
  line-height: 1.13333333;
}
@media (max-width: 767px) {
  .cbdModule--titleLead .titleLead {
    font-size: 22px;
    line-height: 1.36363636;
  }
}
#expo {
  float: left;
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 45.41666667%;
}
#slides {
  overflow: hidden;
  position: relative;
  max-width: 100% !important;
  max-height: 10000px !important;
  z-index: 1;
}
#slides .slide {
  width: 100%;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
#expo div.link {
  display: none;
}
h1 {
  font-size: 60px;
  line-height: 1;
  color: #1A4066;
  font-weight: normal;
}
h1 i {
  font-style: normal;
  font-family: 'DINNextLight', helvetica, sans-serif;
}
.content--lightblue h1 {
  color: #fff;
}
h2 {
  text-align: right;
  font-size: 17px;
  line-height: 1.17647059;
  color: #1A4066;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: normal;
  padding-bottom: 26px;
}
.content--norm .area .flat h2 {
  padding-bottom: 0;
}
.content--norm .area .edge h2 {
  text-transform: none;
  font-size: 14px;
  line-height: 1.14285714;
}
h3 {
  font-weight: normal;
  text-align: right;
  font-size: 60px;
  color: #1A4066;
  line-height: 1;
  letter-spacing: 0;
  padding-top: 22px;
}
.content--norm .area .flat h3 {
  padding-top: 0;
}
.content--norm .area .edge h3 {
  font-size: 20px;
  line-height: 1.2;
  padding-top: 0;
}
h4 {
  font-weight: normal;
  color: #1A4066;
  font-size: 30px;
  line-height: 1.13333333;
  letter-spacing: 0;
}
h5 {
  color: #1A4066;
  font-size: 25px;
  line-height: 1.08;
  font-weight: normal;
}
strong {
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
}
.text {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
.pale {
  text-align: right;
  font-size: 14px;
  line-height: 1.14285714;
  color: #1A4066;
  font-style: italic;
  border-right: 3px solid #1A4066;
  padding-right: 20px;
}
.loud {
  font-size: 25px;
  line-height: 1.2;
  color: #B4B4B4;
  font-style: italic;
  border-top: 1px solid #B4B4B4;
  border-bottom: 1px solid #B4B4B4;
  padding: 30px 0;
}
.skew {
  padding-top: 80px;
  background-size: 70px 60px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/quotes-blue.svg);
  font-size: 32px;
  line-height: 1.125;
  font-style: italic;
}
a {
  color: #000;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #1A4066;
}
.ns-dynTextfield {
  float: left;
  width: 100%;
}
.cbdModule--newsTitleByLine {
  margin-top: 60px;
  margin-bottom: -54px;
}
.cb-layout3 .cbdModule--newsTitleByLine,
.cb-layout4 .cbdModule--newsTitleByLine {
  margin-top: 120px;
  margin-bottom: -114px;
}
@media (max-width: 767px) {
  .cb-layout3 .cbdModule--newsTitleByLine,
  .cb-layout4 .cbdModule--newsTitleByLine {
    margin-top: 60px;
    margin-bottom: -54px;
  }
}
.titleByLine {
  font-size: 17px;
  line-height: 1.17647059;
  color: #1A4066;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-weight: normal;
}
.teaserText {
  margin-top: 30px;
  margin-bottom: 30px;
  outline: 2px solid #71CAF3;
  padding-top: 30px;
  outline-offset: 3px;
}
#view .teaserText {
  display: none;
}
#edit .teaserText {
  position: relative;
}
#edit .teaserText:before {
  content: 'Text des Teasers (Nur im Editor sichtbar)';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1.33333333;
  min-height: 24px;
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 1px;
  background-color: #71CAF3;
  color: #fff;
  padding: 4px 10px;
}
.cbdModule--teaserTitle {
  margin-top: 70px;
}
.cbdModule--teaserTitle .teaserTitle {
  margin-top: 30px;
}
.content--norm .area,
.content--norm .fakeArea {
  display: flex;
  flex-wrap: wrap;
}
.content--norm .area.main,
.content--norm .fakeArea.main {
  margin-top: 90px;
}
.cb-layout3 .content--norm .area.main,
.cb-layout3 .content--norm .fakeArea.main {
  margin-top: 0;
}
.content--blue .area,
.content--norm .fakeArea {
  margin-top: 70px;
  margin-bottom: 70px;
}
.content--lightgray .area,
.content--norm .fakeArea {
  margin-top: 30px;
  margin-bottom: 30px;
}
.content--video .area,
.content--norm .fakeArea {
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .content--video .area,
  .content--norm .fakeArea {
    margin-top: 30px;
  }
}
.cb-layout4 .content--norm .area {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.cb-layout4 .content--norm .area div.unit {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.cb-layout4 .content--norm .area div.unit div.part {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  padding: 0;
}
.content--norm .area .flat {
  border: 1px solid #B4B4B4;
  box-sizing: border-box;
  margin-top: 16px;
  margin-bottom: 16px;
}
.content--norm .area .flat.slim div.body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}
.content--norm .area .edge {
  background: #fff;
  margin-top: 16px;
  margin-bottom: 16px;
}
div.form {
  margin-top: 15px;
  margin-bottom: 15px;
}
div.form div.part {
  margin-top: 15px;
  margin-bottom: 15px;
}
div.form .cb-form-required {
  font-size: 12px;
}
div.form .tile .name {
  padding-bottom: 0;
}
div.form .pile .name {
  padding-bottom: 10px;
}
div.form div.tick.tile {
  margin-top: -17px;
}
div.form div.tick.tile span.name {
  display: none;
}
div.form div.tick.tile div.ctrl {
  width: 100% !important;
}
div.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  margin: 5px 0;
  position: relative;
}
div.form div.tick div.ctrl > div input {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  margin: 0;
}
div.form div.tick div.ctrl > div label {
  display: block;
  margin: 0;
  padding-left: 24px;
  line-height: 1.33333333;
}
div.form select {
  height: 30px;
  background: #fff;
  font-size: 14px;
  border-color: #B4B4B4;
}
div.form div.foot {
  float: left;
  width: 100%;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  padding: 0;
  box-sizing: border-box;
  padding-left: 16.96606786%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 1023px) {
  div.form div.foot {
    padding-left: 16.9047619%;
  }
}
@media (max-width: 767px) {
  div.form div.foot {
    padding-left: 0;
    width: 90%;
  }
}
div.form input.submit {
  float: left;
  display: block;
  position: relative;
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: 0.107em;
  color: #B4B4B4;
  background: transparent;
  border: 1px solid #B4B4B4;
  text-decoration: none;
  padding: 14px 100px 14px 46px;
  background-size: 48px 48px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/submit.svg);
}
div.form input.submit:hover,
div.form input.submit:focus {
  background-color: #1A4066;
  color: #fff;
}
div.form input.text,
div.form textarea {
  box-shadow: none;
  min-height: 36px;
  border-radius: 6px;
  border: 1px solid #B4B4B4;
  padding: 3px 10px;
}
div.form .tile label.name {
  line-height: 1.33333333;
  padding: 6px 0;
}
.text-section {
  float: left;
  width: 100%;
}
#view div.pict + div.text .text-section--pale:first-child,
#view div.cb-widget + div.text .text-section--pale:first-child {
  margin-top: -30px;
}
.text-section--loud {
  box-sizing: border-box;
  padding-right: 170px;
}
@media (max-width: 767px) {
  .text-section--loud {
    padding-right: 0;
  }
}
.list {
  color: #B4B4B4;
  font-size: 20px;
  line-height: 1.4;
  margin-top: -7px;
  margin-bottom: -7px;
}
.list--bullet {
  padding-left: 0;
  list-style-type: none;
}
.list--bullet li {
  padding-left: 50px;
  position: relative;
  padding-top: 7px;
  padding-bottom: 7px;
}
.list--bullet li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 20px;
  height: 1px;
  width: 30px;
  background: #B4B4B4;
}
@media (max-width: 767px) {
  .list--bullet li:before {
    top: 16px;
  }
}
.list--numbered li {
  margin-left: 16px;
}
div.load {
  background: none !important;
}
div.link,
div.load {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
div.link a.open,
div.load a.open,
div.link span.open,
div.load span.open,
div.link a.load,
div.load a.load {
  float: left;
  display: block;
  position: relative;
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: 0.107em;
  color: #B4B4B4;
  border: 1px solid #B4B4B4;
  text-decoration: none;
  padding: 14px 100px 14px 46px;
  cursor: pointer;
}
.content--norm .area .edge div.link a.open,
.content--norm .area .edge div.load a.open,
.content--norm .area .edge div.link span.open,
.content--norm .area .edge div.load span.open,
.content--norm .area .edge div.link a.load,
.content--norm .area .edge div.load a.load {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}
@media (max-width: 767px) {
  div.link a.open,
  div.load a.open,
  div.link span.open,
  div.load span.open,
  div.link a.load,
  div.load a.load {
    padding-left: 20px;
    padding-right: 68px;
    word-wrap: break-word;
  }
}
div.link a.open:after,
div.load a.open:after,
div.link span.open:after,
div.load span.open:after,
div.link a.load:after,
div.load a.load:after {
  content: '';
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 2;
  width: 48px;
  height: 48px;
  height: calc(100% + 2px);
  background-size: 15px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/link.svg);
  background-color: #1A4066;
}
div.link a.open:hover,
div.load a.open:hover,
div.link span.open:hover,
div.load span.open:hover,
div.link a.load:hover,
div.load a.load:hover,
div.link a.open:focus,
div.load a.open:focus,
div.link span.open:focus,
div.load span.open:focus,
div.link a.load:focus,
div.load a.load:focus {
  background: #1A4066;
  border-color: #1A4066;
  color: #fff;
}
div.pict img {
  margin-left: auto;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.33333333em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'DINNextLight', helvetica, sans-serif;
  font-size: 18px;
  font-size: 1.8pxrem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.33333333em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 18px;
  font-size: 1.8pxrem;
  color: #444;
  font-weight: normal;
  font-family: 'DINNextLight', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  float: left;
  display: block;
  background-color: #1A4066;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  color: #fff;
  background-color: #1d4872;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  background-color: #10263d;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.33333333em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
/*** mobile-navi-multilevel-top.less 2014-11-04 ***/
.navi {
  display: none;
}
#mobile-navi {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding-left: 0%;
  padding-right: 0%;
  z-index: 2000;
  height: 100%;
  max-height: 0;
  overflow: hidden;
  background: #fff;
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.naviani {
  float: left;
  width: 100%;
  opacity: 0;
  transform: translateY(-200px);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
  margin-top: 92px;
}
@media (max-width: 767px) {
  .naviani {
    margin-top: 72px;
  }
}
body.cb-toggle-target-active .naviani {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.8s 0.1s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.togglenavigation {
  position: fixed;
  top: 30px;
  right: 3.7414966%;
  display: block;
  width: 46px;
  height: 32px;
  z-index: 211;
  cursor: pointer;
  color: #1A4066 !important;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}
.togglenavigation:active > span {
  background: #122c45 !important;
}
.togglenavigation > span {
  left: 0;
  top: 50%;
  position: absolute;
  display: block;
  height: 2px;
  width: 46px;
  margin-top: -1px;
  background: currentColor;
}
.togglenavigation > .tline-1 {
  margin-top: -16px;
}
.togglenavigation > .tline-2,
.togglenavigation > .tline-3 {
  width: 32px;
}
.togglenavigation > .tline-4 {
  margin-top: 14px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  width: 46px;
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  transform: rotate(45deg);
}
@media (max-width: 767px) {
  .togglenavigation {
    top: 20px;
    margin: 0;
    right: 5%;
    width: 32px;
    height: 24px;
    top: 24px;
  }
  .togglenavigation > span {
    width: 32px;
  }
  .togglenavigation > .tline-1 {
    margin-top: -12px;
  }
  .togglenavigation > .tline-2,
  .togglenavigation > .tline-3 {
    width: 20px;
  }
  .togglenavigation > .tline-4 {
    margin-top: 10px;
  }
  .cb-toggle-target-active .togglenavigation > .tline-2,
  .cb-toggle-target-active .togglenavigation > .tline-3 {
    width: 32px;
  }
}
body.cb-toggle-target-active #mobile-navi {
  max-height: 100%;
  overflow: scroll;
}
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
  text-align: center;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
  box-shadow: 0 2px 2px rgba(180, 180, 180, 0.4);
}
#mobile-navi .navi .item .menu {
  display: block;
  color: #787878;
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.5;
  padding: 11px 0;
  position: relative;
}
#mobile-navi .navi .item .menu:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background: #1A4066;
}
#mobile-navi .navi .item .menu.path {
  color: #1A4066;
}
#mobile-navi .navi .item .menu.path:after {
  height: 6px;
}
#mobile-navi .navi.sub1 {
  box-sizing: border-box;
  padding: 0 5%;
  box-shadow: 0 2px 2px rgba(180, 180, 180, 0.4);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
#mobile-navi .navi.sub1 .item {
  width: auto;
  box-shadow: none;
}
#mobile-navi .navi.sub1 .item.init .menu:before {
  display: none;
}
#mobile-navi .navi.sub1 .item .menu {
  font-style: normal;
  font-family: 'DINNextRegular', helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.6;
}
#mobile-navi .navi.sub1 .item .menu:before {
  content: '|';
  display: inline-block;
  margin: 0 3px;
}
#mobile-navi .navi.sub1 .item .menu:after {
  display: none;
}
#mobile-navi .navi.sub1 .item .menu.path {
  font-family: 'DINNextBold', helvetica, sans-serif;
  color: #1A4066;
}
#mobile-navi .navi.sub3 {
  margin: 0;
}
#mobile-navi .navi.sub3 .item {
  box-shadow: none;
}
#mobile-navi .navi.sub3 .item.exit {
  margin-bottom: 30px;
}
#mobile-navi .navi.sub3 .item .menu {
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  font-family: 'DINNextRegular', helvetica, sans-serif;
  padding: 5px 0;
}
#mobile-navi .navi.sub3 .item .menu:after {
  display: none;
}
#mobile-navi .navi.sub3 .item .menu.path {
  color: #1A4066;
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 37px;
  height: 37px;
  background: #ffffff;
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active {
  transition: all 0.218s;
}
#mobile-navi div.sub3 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
#home {
  height: 56px;
  margin: 8px 0 8px 5%;
}
.section--multimood {
  margin-top: 72px;
}
@media (max-width: 767px) {
  .wrapper {
    font-size: 16px;
    line-height: 1.25;
  }
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 11px;
    padding-bottom: 10px;
  }
  h3 {
    font-size: 35px;
    padding-top: 10px;
  }
  h4 {
    font-size: 22px;
    line-height: 1.09090909;
  }
  h5 {
    font-size: 20px;
    line-height: 1.1;
  }
  .pale {
    font-size: 12px;
    line-height: 1.08333333;
    padding-right: 14px;
  }
  .loud {
    font-size: 19px;
    line-height: 1.10526316;
    padding: 15px 0;
  }
  .skew {
    font-size: 18px;
    line-height: 1.11111111;
    background-size: 50px 43px;
    padding-top: 60px;
  }
  .list {
    font-size: 18px;
    line-height: 1.11111111;
  }
}
.cbdModule--teaserTitle .teaserTitle {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
@media (max-width: 767px) {
  .cbdModule--teaserTitle .teaserTitle {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.area,
.fakeArea {
  width: 100%;
}
.area > .unit,
.fakeArea > .unit,
.area > .fakeUnit,
.fakeArea > .fakeUnit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area .foot,
.fakeArea .foot {
  margin-right: 5%;
  margin-left: 5%;
}
.area .part,
.fakeArea .part,
.area .fakePart,
.fakeArea .fakePart {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.area > .slim .part,
.fakeArea > .slim .part,
.area > .slim .fakePart,
.fakeArea > .slim .fakePart {
  width: 90%;
}
@media (max-width: 767px) {
  .content--norm .area .flat,
  .content--norm .area .edge {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
  }
}
.cb-layout4 .content--norm .area {
  width: 90%;
  margin-left: 5%;
}
.content--norm .area .seam.slim {
  width: 50%;
}
.content--norm .area .seam.slim .part {
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
}
@media (max-width: 767px) {
  .part,
  .fakePart {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/* scan.less 2013-1-16 */
.scan .cb-hybrid {
  color: #B4B4B4;
  text-decoration: none;
}
.scan .cb-hybrid:hover,
.scan .cb-hybrid:focus {
  color: #1A4066;
}
.swap {
  text-align: center;
}
.scan form,
.scan > table,
.scan fieldset {
  width: 100% !important;
}
.scan > table {
  margin: 0 !important;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.scan > table tr {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.scan > table tr td {
  box-sizing: border-box;
  display: block;
  width: 50px;
  height: 50px;
  margin-right: -1px;
  border: 1px solid #B4B4B4;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-family: 'DINNextMedium', helvetica, sans-serif;
}
.scan > table tr td.prev,
.scan > table tr td.next {
  font-family: 'DINNextLight', helvetica, sans-serif;
  font-weight: 400;
}
.swap td > span,
.swap td > .cb-hybrid {
  box-sizing: border-box;
  display: block;
  width: 50px;
  height: 50px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  color: #B4B4B4;
}
td.prev,
td.next {
  color: rgba(180, 180, 180, 0.3);
}
td.prev .cb-hybrid,
td.next .cb-hybrid {
  color: #1A4066;
}
.unit.cb-batch .same {
  background-color: #1A4066;
  color: #fff;
}
/******* module-filter.less 2013-1-16 *******/
.cb-count {
  padding: 0 !important;
}
.scan th {
  text-align: center;
  white-space: nowrap;
}
.tabs td,
.time td,
.beta td {
  text-align: center;
}
.mese table {
  table-layout: fixed;
}
.mese td,
.mese tbody th {
  text-align: right;
}
.mese td > .cb-hybrid {
  display: block;
}
.cats span {
  padding: 0;
}
.scan > p {
  font-size: 0;
  text-align: center;
}
.scan > p > span {
  padding: 0;
}
.scan > p > .same,
.scan > p > span > .cb-hybrid {
  padding: 0 0.3em;
}
p > span > .cb-hybrid {
  display: inline-block;
}
p > .same,
p > span > .same {
  display: inline-block;
  background: none !important;
}
/******* module-filter-small.less 2013-1-16 *******/
.cb-mobile-filter {
  font-size: 18px;
  font-size: 1.8rem;
  z-index: 20;
  display: inline-block;
  width: 100%;
  background: #1A4066;
  color: #555;
}
.cb-mobile-filter-link {
  width: inherit;
}
.cb-icon-filter:before {
  display: inline-block;
  width: 100%;
  text-align: center;
  text-decoration: inherit;
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  font-family: 'cbfont';
  line-height: 1em;
  speak: none;
  padding: 0 0.2em 0 0.6em;
  width: 1.2em;
  content: '\f0c9';
}
.cb-mobile-filter-items {
  padding-bottom: 1em;
  width: 100%;
  font-size: 16px;
  line-height: 1.2;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
.cb-mobile-filter-items .cb-hybrid {
  display: block;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  color: #fff;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.4);
}
.cb-mobile-filter-items .cb-hybrid:hover,
.cb-mobile-filter-items .cb-hybrid:focus,
.cb-mobile-filter-items .cb-hybrid:active {
  background: #153352;
  color: #ffffff;
  text-decoration: none;
}
.cb-mobile-filter-items .mese td > span,
.cb-mobile-filter-items li > span {
  display: block;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}
.cb-mobile-filter-items > .cb-filter {
  margin: 0 !important;
  width: 100% !important;
}
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid,
.epoc td > .cb-hybrid,
.year td > .cb-hybrid,
.mese td > .cb-hybrid,
.week td > .cb-hybrid,
.days td > .cb-hybrid {
  padding-right: 2%;
  padding-left: 2%;
}
.cb-mobile-filter-items li {
  display: inline-block;
  overflow: hidden;
  width: 50%;
  text-overflow: ellipsis;
}
.cb-mobile-filter-items li > .cb-hybrid {
  position: relative;
  overflow: hidden;
  padding-right: 4%;
  padding-left: 4%;
  text-overflow: ellipsis;
}
.cb-mobile-filter-items li > .cb-hybrid:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 4em;
  height: 100%;
  /*background-color: #1e1e1e;*/
  background-image: -webkit-gradient(linear, left center, right center, from(rgba(30, 30, 30, 0)), to(#1e1e1e));
  background-image: -webkit-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -moz-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -o-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: -ms-linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  background-image: linear-gradient(left, rgba(30, 30, 30, 0), #1e1e1e);
  content: '';
}
.mese tbody th,
.mese td > .cb-hybrid,
.mese td > span {
  padding-right: 0.4em;
  padding-left: 0.4em;
}
.cb-mobile-filter-items > .beta {
  text-align: center;
}
.cb-mobile-filter-items > .beta > span {
  float: left;
  padding: 0;
  width: 20%;
  line-height: 3em;
}
.cb-mobile-filter-items > .beta > span > .cb-hybrid {
  padding: 0;
}
.cb-mobile-filter-items > .scan > p > span > .cb-hybrid {
  display: inline-block;
  overflow: hidden;
  max-width: 9em;
  vertical-align: middle;
  text-overflow: ellipsis;
}
.font9 {
  font-size: 12px;
  font-size: 1.2rem;
}
.font12 {
  font-size: 16px;
  font-size: 1.6rem;
}
.font15 {
  font-size: 21px;
  font-size: 2.1rem;
}
.font18 {
  font-size: 25px;
  font-size: 2.5rem;
}
.font21 {
  font-size: 29px;
  font-size: 2.9rem;
}
.font24 {
  font-size: 33px;
  font-size: 3.3rem;
}
.font27 {
  font-size: 37px;
  font-size: 3.7rem;
}
.font30 {
  font-size: 42px;
  font-size: 4.2rem;
}
.font32 {
  font-size: 44px;
  font-size: 4.4rem;
}
.cb-mobile-filter-items .cb-count,
.cb-mobile-filter-items .days td > span,
.cb-mobile-filter-items .week td > span,
.cb-mobile-filter-items .year td > span {
  display: none;
}
.cb-mobile-filter-items .same > span:first-child {
  display: block;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.keys input {
  display: table;
  margin: 0.3em auto;
  width: 86%;
  appearance: searchfield;
}
.keys input + .cb-hybrid {
  display: none;
}
.scan select {
  margin: 0.4em 2%;
  width: 96%;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #153352;
  color: #fff;
  text-decoration: none;
}
/*# sourceMappingURL=./screen-small.css.map */